import { ApiRoot } from "../../../../@WUM/core/RestFullApi/api";


const CertifcationProfil = (props:{value:any}) => {

    // Fetchdownlaod('Conf/certificate/bulk/','There is no certificate available for you. If you have any questions, please contact <a href="mailto:support@icymare.com">support@icymare.com</a>.')
    return(
        <>
            <div id={'download'} />
            <h3 className={"p-5"}>The certificates of our conference from 2024 are in preparation and will be provided as soon as possible (next week). </h3>
        </>
    )

}

export default CertifcationProfil;


export const Fetchdownlaod = (url:any,errorMsg:any) => {
    fetch(ApiRoot() + url + localStorage.getItem('userid') + '/', {
        method: 'GET',
        headers: {}
    })
        .then((response) => response.blob())
        .then((blob) => {
            let div = document.getElementById(
                'download'
            ) as HTMLDivElement
            if(blob.size === 2) {
                var label = document.createElement('h3')
                label.innerHTML = errorMsg;
                label.style.marginLeft = "10px"
                if (div !== null) {
                    if (!div.hasChildNodes()) {
                        div.appendChild(label)
                    }
                }
                return;
            }
            var url = window.URL.createObjectURL(blob)
            var object = document.createElement('object')
            object.data = url
            object.ariaLabel = 'pdf'
            object.width = '100%'
            object.height ='1000px'

            if (div !== null) {
                if (!div.hasChildNodes()) {
                        div.appendChild(object)
                }
            }
        })
}
